/* eslint-disable react/react-in-jsx-scope */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import InterrnalErrorPage from './pages/InternalErrorPage/InterrnalErrorPage';
import GetInTouch from './pages/GetInTouch/GetInTouch';
import HomeNew from './pages/HomeNew/HomeNew';
import 'bootstrap/dist/css/bootstrap.min.css';
import IgniteYouth from './pages/IgniteYouth/IgniteYouth';
import Products from './pages/Products/Products';
import ContactUs from './pages/ContactUs/ContactUs';
import Careers from './pages/Careers/Careers';
import About from './pages/About/About';
import TermsAndCondition from './pages/TermsAndCondition/TermsAndCondition';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();
function App() {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomeNew />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/products" element={<Products />} />
        <Route exact path="/ignite-youth" element={<IgniteYouth />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route path="/500" element={<InterrnalErrorPage />} />
        <Route path="/get-in-touch" element={<GetInTouch />} />
        <Route path="/privacy" element={<TermsAndCondition/>} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
