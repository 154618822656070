import React, { useEffect, useState, useRef } from 'react'
import classes from './ContactUs.module.css'
import Layout from '../../Layout/Layout'
import { send } from 'emailjs-com';
 ;

const ContactUs = () => {
    const formRef = useRef();

    const handleReset = () => {
        formRef.current.reset();
    };
    const [loading, setLoading] = useState(false);
    const [toSend, setToSend] = useState({
        full_name: '',
        message: '',
        phone_number: '',
        company_name: '',
        email: '',
    });

    const onSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        send('service_auzm3mk', 'template_iixsa2e', toSend, '80L_WepszlMPnD5j6')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setLoading(false);
                alert('email sent');
                handleReset();
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setLoading(false);
            });
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.setItem('currentPage', 5);
    }, []);
    return (
        <Layout>
            <section
            className={classes.ContactUs}>
                <div className={classes.contact}>
                    <h1>
                        Want to Collaborate? Contact Us
                    </h1>
                    <div className={classes.contactform}>
                        <div>
                            <p className={classes.formtitle}>Contact Us</p>
                        </div>
                        <hr />
                        <form onSubmit={onSubmit} ref={formRef}>
                            <div className={classes.formChild}>
                                <label>Full Name
                                </label>
                                <input
                                    name='full_name'
                                    type="text"
                                    placeholder='Enter Your Name'
                                    onChange={handleChange}
                                    required
                                />
                                <label>Email
                                </label>
                                <input
                                    name='email'
                                    type="email"
                                    placeholder='Enter Your Email'
                                    onChange={handleChange}
                                    required
                                />
                                <label >Company Email
                                </label>
                                <input
                                    name='company_name'
                                    type="email"
                                    placeholder='Enter Your Company Email'
                                    onChange={handleChange}
                                    required
                                />
                                <label >Phone Number
                                </label>
                                <input
                                    name='phone_number'
                                    type="number"
                                    placeholder='Enter Your Phone Number'
                                    onChange={handleChange}
                                    required

                                />
                                <label >Your Message
                                </label>
                                <textarea required name='message' placeholder='Enter Your Message...' />

                                {loading === true ? (
                                    <button>
                                        Sending Message
                                    </button>
                                ) : (
                                    <button>
                                        Send Message
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ContactUs
