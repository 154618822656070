import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import Seo from '../../helpers/Seo'
import classes from './Products.module.css'
import Collaborate from '../../components/Collaborate/Collaborate'
import white from '../../assets/images/ellipsewhite.png'
import faded from '../../assets/images/ellipsefade.png'
 

const Products = () => {
    const [imageIndex, setImageIndex] = useState(0);
    const [fade, setFade] = useState(false);
    const pictures = [
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/7T9C4695_1_tzrqf8.png',
        'https://res.cloudinary.com/naijakids/image/upload/q_auto/_A8A6693_jk7t7s.png',
    ]
    const text1 = [
        '“[The] solar makes my shop shine and lively, I am assured of daily power supply and I can testify to reduced noise, air pollution and how the constant, reliable electricity supply makes the shop more habitable…”',
        '“…The solar power makes work faster and more comfortable, brighter lights makes it easier to see my tools clearly. I now open my shop more often, even on weekends and work for longer hours. The cleaner environment, and less noise has made our workplace more comfortable and convenient.”'
    ]
    const text2 = [
        'Raphael E.',
        'Roli A.'
    ]
    const text3 = [
        'Owner, Electronics Shop, Delta State',
        'Owner, Fashion Boutique, Edo State',
    ]

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setFade(true); // Apply fade-out class
    //         setTimeout(() => {
    //             setImageIndex((prevIndex) => (prevIndex + 1) % pictures.length);
    //             setFade(false);
    //         }, 1000);
    //     }, 8000);
    //     return () => clearInterval(intervalId);
    // }, [pictures.length]);

    const handleSwitch = () => {
        setFade(!fade);
        setImageIndex((prevIndex) => (prevIndex + 1) % pictures.length);
        setFade(!fade);
    }

    const [isLoading, setIsLoading] = useState(true);
    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.setItem('currentPage', 2);
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_195_nywbg1.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_198_sekj7m.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Rectangle_20140_cnngag.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/IMG_7729_ycuptx.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Untitled_u6o3uh.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_193_cbytcc.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_192_o2kbrq.png',
        ]
        cacheImages(imgs)
    }, []);
    return (
        <Layout>
            <Seo
                title="Products"
                description="We are making African small businesses and their communities cleaner,  sustainable, and more productive."
                link="https://www.icesolar.co/ignite-youth"
            />
            {isLoading ?
                <div id="spinner" className={`container`}>
                    <div className={`loading`}></div>
                </div>
                :
                <div className={classes.Products}>
                    <div className={classes.ProdTop}>
                        <h1>
                            Our Approach
                        </h1>
                        <h2>
                            We are making African
                            small businesses and their
                            communities <span className={classes.ProdSpan}>cleaner, sustainable, </span>
                            and more <span className={classes.ProdSpan}> productive. </span>

                        </h2>
                    </div>
                    <div className={classes.ProdMain}>
                        <div className={classes.ProdMainText}>
                            <h1>Access to Energy</h1>
                            <h2>
                                Leveraging distributed energy resources, we provide energy-as-a-service to support the seamless transition from dirty self-generation to sustainable clean energy for underserved small businesses
                            </h2>
                        </div>
                        <div  className={classes.ProdImg}>
                            <div className={classes.Images}>
                                <img className={classes.left} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_195_nywbg1.png' alt='' />
                                <img className={classes.right} src='https://res.cloudinary.com/naijakids/image/upload/v1703375651/image_198_4_nbbenx.png' alt='' />
                            </div>
                            <div className={classes.Images}>
                                <img className={classes.left} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Rectangle_20140_cnngag.png' alt='' />
                                <img className={classes.right} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/IMG_7729_ycuptx.png' alt='' />
                            </div>
                        </div>
                    </div>
                    <div  className={classes.Finance}>
                        <h1>
                            Financial Inclusion
                        </h1>
                        <h2>
                            We help underserved small businesses begin their digital transformation and do more
                        </h2>
                    </div>
                    <div  className={classes.ProdBot}>
                        <div className={classes.ProdImg}>
                            <div className={classes.Images}>
                                <img className={classes.mid} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Untitled_u6o3uh.png' alt='' />
                            </div>
                            <div className={classes.Images}>
                                <img className={`${classes.left} ${classes.shadow}`} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/ICE_NK_Ekpunobi_benin_1_uvwsve.jpg' alt='' />
                                <img className={`${classes.right} ${classes.shadow}`} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image2_w7fguw.jpg' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className={classes.Testimonial}>
                        <div  className={classes.Test}>
                            <div className={classes.TestLeft}>
                                <img src={pictures[imageIndex]} alt=''
                                    className={`${classes.fadeInOut} ${fade ? classes.fadeOut : ''}`}
                                />
                            </div>
                            <div className={classes.TestRight}>
                                <h1>{text1[imageIndex]}</h1>
                                <h2>{text2[imageIndex]}</h2>
                                <h3>{text3[imageIndex]}</h3>
                                <div className={classes.slidecontrol}>
                                    <img src={fade === true ? faded : white} alt='' onClick={handleSwitch} />
                                    <img src={fade === false ? faded : white} alt='' onClick={handleSwitch} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Collaborate />
                </div>}
        </Layout>
    )
}

export default Products
