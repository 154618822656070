import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import Seo from '../../helpers/Seo'
import classes from './IgniteYouth.module.css'
import Collaborate from '../../components/Collaborate/Collaborate'
import { ReactComponent as Add } from '../../assets/images/add.svg';
import { ReactComponent as Minus } from '../../assets/images/minus.svg';
const IgniteYouth = () => {

    const [accordionShow, setAccordionShow] = useState(true);
    const [accordionShow2, setAccordionShow2] = useState(false);
    const [accordionShow3, setAccordionShow3] = useState(false);
    const [accordionShow4, setAccordionShow4] = useState(false);
    const [accordionShow5, setAccordionShow5] = useState(false);
    const handleAccordion = () => {
        setAccordionShow(!accordionShow)
    }
    const handleAccordion2 = () => {
        setAccordionShow2(!accordionShow2)
    }
    const handleAccordion3 = () => {
        setAccordionShow3(!accordionShow3)
    }
    const handleAccordion4 = () => {
        setAccordionShow4(!accordionShow4)
    }
    const handleAccordion5 = () => {
        setAccordionShow5(!accordionShow5)
    }
    const [isLoading, setIsLoading] = useState(true);

    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.setItem('currentPage', 3);
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002274_1_axn2uh.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002275_uzhicg.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002273_hxw2uq.png'
        ]
        cacheImages(imgs)
    }, []);

    return (
        <>
            <Seo
                title="Ignite Youth"
                description="The Future is Youth"
                link="https://www.icesolar.co/ignite-youth"
            />
            <Layout>
                {isLoading ?
                    <div id="spinner" className={`container`}>
                        <div className={`loading`}></div>
                    </div>
                    :
                    <div className={classes.Ignite}>
                        <div className={classes.IgniteTop}>
                            <div className={classes.IgniteText}>
                                <h1>The Future is Youth</h1>
                            </div>
                        </div>
                        <div
                        
                            className={classes.IgniteMain}>
                            <div  className={classes.IgniteLeft}>
                                <h1>
                                    Ignite <span style={{ color: '#378AFF' }}>Overview
                                    </span>
                                </h1>
                                <h2>
                                    Ignite is a hybrid youth development
                                    fellowship program in partnership
                                    with African universities to mobilize
                                    youth for digital upskilling in
                                    preparation for the future of work.
                                </h2>
                            </div>
                            <div  className={classes.IgniteRight}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002274_1_axn2uh.png' alt='' />
                            </div>
                        </div>
                        <div
                            className={classes.IgniteMain}
                            style={{ backgroundColor: '#fff' }}
                        >
                            <div   className={classes.IgniteLeft}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002275_uzhicg.png' alt='' />
                            </div>
                            <div   className={classes.IgniteRight}>
                                <h1
                                    style={{ color: 'black' }}
                                >
                                    Ignite <span style={{ color: '#378AFF' }}>Mission
                                    </span>
                                </h1>
                                <h2
                                    style={{ color: 'black' }}
                                >
                                    We believe youth are pivotal to the ongoing digital
                                    transformation and clean energy revolution in Africa.
                                    Ignite provides digital upskilling to prepare youth
                                    in underserved communities for
                                    the future of work.
                                </h2>
                            </div>
                        </div>
                        <div
                            className={classes.IgniteMain}
                            style={{ backgroundColor: '#004EBB' }}>
                            <div  className={classes.IgniteLeft}>
                                <h1>Ignite <span style={{ color: '#000' }}> Vision
                                </span>
                                </h1>
                                <h2
                                >
                                    We aim to increase Africa’s productivity
                                    by upskilling <span
                                        style={{ color: 'yellow' }}> 100,000
                                    </span> youth with
                                    relevant digital skills.
                                </h2>
                            </div>
                            <div  className={classes.IgniteRight}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000002273_hxw2uq.png' alt='' />
                            </div>
                        </div>
                        <div className={classes.faq}>
                            <div  className={classes.faqName}>
                                <h1>
                                    Frequently Asked Questions
                                </h1>
                                <h2>
                                    Everything you need to know about Ignite
                                </h2>
                            </div>
                            <div className={classes.FaqWrap}>
                                <div  className={classes.Wrapper}>
                                    <div className={classes.Box}>
                                        <div>
                                            <h1 onClick={handleAccordion}
                                            >
                                                What is Ignite?
                                            </h1>
                                            <h2
                                                style={{ display: accordionShow ? 'flex' : 'none' }}
                                            >
                                                Ignite is a hybrid youth development fellowship
                                                program to designed to mobilize university
                                                graduates (ages 18-35) for data-driven
                                                community engagement and provide digital
                                                upskilling for the future of work.
                                                With strong strategic partnerships,
                                                ICE implements Ignite with various African
                                                universities and technology companies by
                                                working to deepen technical training and
                                                connecting fellows to potential careers in
                                                technology and renewable energy.
                                            </h2>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}>
                                            {accordionShow ? <Minus onClick={handleAccordion} /> :
                                                <Add onClick={handleAccordion} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div  className={classes.Wrapper}>
                                    <div className={classes.Box}>
                                        <div>
                                            <h1 onClick={handleAccordion2}>
                                                What are all of the components of the program?</h1>
                                            <h2
                                                style={{ display: accordionShow2 ? 'flex' : 'none' }}
                                            >
                                                The fellowship has three components <br />(1) Market intelligence and community engagement,
                                                <br />(2) Industry readiness and professional development,
                                                and <br />(3) Career matching and placement for opportunities in technology and renewable energy.
                                            </h2>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}>
                                            {accordionShow2 ? <Minus onClick={handleAccordion2} /> :
                                                <Add onClick={handleAccordion2} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div  className={classes.Wrapper}>
                                    <div className={classes.Box}>
                                        <div>
                                            <h1 onClick={handleAccordion3}>
                                                How long is the program?
                                            </h1>
                                            <h2
                                                style={{ display: accordionShow3 ? 'flex' : 'none' }}
                                            >
                                                Each cohort lasts for six months.
                                            </h2>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}>
                                            {accordionShow3 ? <Minus onClick={handleAccordion3} /> :
                                                <Add onClick={handleAccordion3} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div  className={classes.Wrapper}>
                                    <div className={classes.Box}>
                                        <div>
                                            <h1 onClick={handleAccordion4}>
                                                How do I apply?</h1>
                                            <h2
                                                style={{ display: accordionShow4 ? 'flex' : 'none' }}
                                            >
                                                Applications are currently closed. Check this page and follow us on social media for information on upcoming cohorts.
                                            </h2>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}>
                                            {accordionShow4 ? <Minus onClick={handleAccordion4} /> :
                                                <Add onClick={handleAccordion4} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div  className={classes.Wrapper}>
                                    <div className={classes.Box} >
                                        <div>
                                            <h1 onClick={handleAccordion5}>
                                                How can I get my college or university involved?
                                            </h1>
                                            <h2
                                                style={{ display: accordionShow5 ? 'flex' : 'none' }}
                                            >
                                                Ask your school's administration to send an email to ignite@icesolar.co
                                            </h2>
                                        </div>
                                        <div style={{ cursor: 'pointer' }}>
                                            {accordionShow5 ? <Minus onClick={handleAccordion5} /> :
                                                <Add onClick={handleAccordion5} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Collaborate />
                    </div>
                }
            </Layout>
        </>
    )
}

export default IgniteYouth
