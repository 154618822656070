import NavbarComp from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const Layout = (props) => {
  return (
    <>
      <NavbarComp />
      {props.children}
      <Footer/>
    </>
  );
};

export default Layout;
