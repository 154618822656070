import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import classes from './Careers.module.css'
import Seo from '../../helpers/Seo'
const Careers = () => {

    const [isLoading, setIsLoading] = useState(true);
    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.setItem('currentPage', 4);
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Frame_1000003261_suawzr.png',
        ]
        cacheImages(imgs)
    }, []);

    return (
        <>
            <Seo
                title="Careers"
                description="Come Work With Us"
                link="https://www.icesolar.co/careers"
            />
            <Layout>
                {isLoading ?
                    <div id="spinner" className={`container`}>
                        <div className={`loading`}></div>
                    </div>
                    :
                    <section
                  
                    className={classes.careers}>
                        <div className={classes.careerTop}>
                            <h1>
                                Come Work With Us
                            </h1>
                            <h2>
                                Are you looking to kickstart your career or an experienced professional looking to make a difference?
                            </h2>
                            <div className={classes.image}>
                                <div className={classes.wrapper}>
                                    <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Frame_1000003261_suawzr.png' alt='' />
                                </div>
                            </div>
                        </div>
                        <div  className={classes.careerOpen}>
                            <h1>
                                Our Openings
                            </h1>
                            <hr className={classes.horizontal} />
                            <h2>
                                No Open Roles
                            </h2>
                            <h3>Check back soon!</h3>
                            <hr className={classes.horizontal} />
                        </div>
                        <div className={classes.careercards}>
                            <div className={classes.cardbox}>
                                <div className={classes.card}>
                                    <h1>
                                        Impact-Focused Work</h1>
                                    <h2>
                                        Impact is at the core of our work and we are focused on making a real difference for underserved communities.
                                    </h2>
                                </div>
                                <div className={classes.card}>
                                    <h1>
                                        Competitive Salary
                                    </h1>
                                    <h2>
                                        We offer competitive salaries and
                                        the opportunity for you to have
                                        good career progression.
                                    </h2>
                                </div>
                                <div className={classes.card}>
                                    <h1>
                                        Generous PTO
                                    </h1>
                                    <h2>
                                        We are very ambitious but we also recognize the need to rest and recharge.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </Layout>
        </>
    )
}

export default Careers
