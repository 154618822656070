
import React, { useEffect, useRef, useState } from 'react';
import classes from './HomeNew.module.css';
import Layout from '../../Layout/Layout';
import thumb from '../../assets/images/thumb.png'
import Seo from '../../helpers/Seo';
import { useNavigate, Link } from 'react-router-dom';

function HomeNew() {
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);
  // creating ref items to pin point each div to scroll to
  const refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [currentSection, setCurrentSection] = useState(0);

  const executeScroll = () => refs[currentSection].current.scrollIntoView();

  const handleButtonClick = () => {
    executeScroll({
      behavior: 'smooth',

    });
    setCurrentSection((prevSection) => (prevSection + 1) % refs.length);
  };
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  useEffect(() => {
    // window.scrollTo(0, 0)
    sessionStorage.setItem('currentPage', 0);
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/contact-us")
  }

  return (
    <>
      <Seo
        title="ICE SOLAR"
        description="ICE is deploying distributed microutilities to empower the underserved."
        link="https://www.icesolar.co/"
      />
      <Layout
      >
        <div className={classes.home_main} 
        >
          <div className={classes.home}>
            <div className={classes.herosection}>
              <div className={classes.hero_container}>
                <img
                  src={thumb}
                  className={`${classes.thumb} ${classes.tiny}`}
                  alt="ice solar thumbnail"
                  style={{ opacity: isVideoLoaded ? 0 : 1 }}
                />
                <video src="https://res.cloudinary.com/naijakids/video/upload/q_auto/hero_iytnoy.mp4" id="bgVideo" autoPlay loop muted playsInline
                  onLoadedData={onLoadedData}
                  style={{ opacity: isVideoLoaded ? 1 : 0 }}
                />
                <div className={classes.vanish} />
                <div className={classes.hero_content}>
                  <div className={classes.Content1} ref={refs[0]}>
                    <>
                      <h1>Connecting the Unconnected</h1>
                      <h2>
                        We use digital technology to empower underserved <br className={classes.break} /> small businesses for sustainable transformation.
                      </h2>
                      <button onClick={handleClick}>
                        Get in touch
                      </button>
                    </>
                  </div >
                  <div className={classes.Content2}>
                    <div className={`${classes.Content2Img} ${classes.mobileScroll}`} ref={refs[1]} >
                      <Link to='/products'>

                        <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/one_1_z23i4w.png' alt='' />
                      </Link>

                      <div >
                        <Link to='/products'>
                          <h1>
                            Clean Energy
                          </h1>
                        </Link>
                        <h2>We Support Underserved small businesses with their <br className={classes.break} /> transition to clean energy <br className={classes.break} /></h2>
                      </div>
                    </div>
                  </div>
                  <div className={classes.Content2} ref={refs[2]} >
                    <div className={classes.Content2Img}>
                      <Link to='/products'>

                        <img src='https://res.cloudinary.com/naijakids/image/upload/v1703317433/two-removebg-preview_2_1_gd2zw8.png' alt='' />
                      </Link>
                      <div>
                        <Link to='/products'>
                          <h1>

                            Digital Transformation</h1>
                        </Link>
                        <h2>We use digital technology to provide value added <br className={classes.break} />services to increase small buiness productivy </h2>
                      </div>
                    </div>
                  </div>
                  <div className={classes.Content2} ref={refs[3]}>
                    <div className={classes.Content2Img}>
                      <Link to='/ignite-youth'>

                        <img src='https://res.cloudinary.com/naijakids/image/upload/v1703317409/three_1_zea8og.png' alt='' />
                      </Link>

                      <div>
                        <Link to='/ignite-youth'>
                          <h1>Community Development</h1>
                        </Link>
                        <h2>We provide digital upskilling to reach underserved  <br className={classes.break} /> communities and prepare youth for the future of work </h2>
                      </div>
                    </div>
                  </div>
                  <div className={classes.arrowimg}>
                    <img onClick={handleButtonClick} src='https://res.cloudinary.com/naijakids/image/upload/v1703323648/arrow-up_o3vbya.png' alt='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>

  );
}

export default HomeNew;
