import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import classes from './About.module.css'
import Collaborate from '../../components/Collaborate/Collaborate'
import Seo from '../../helpers/Seo'


const About = () => {
    const [isLoading, setIsLoading] = useState(true);
    const cacheImages = async (srcArray) => {
        const promises = await srcArray.map((src) => {
            return new Promise(function (resolve, reject) {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        });
        await Promise.all(promises)
        setIsLoading(false)
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        sessionStorage.setItem('currentPage', 1);
        const imgs = [
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_183_txvpbj.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_184_vbiktl.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_185_yr3zph.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_186_lbfsin.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_187_znxj41.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_196_imvftn.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_182_waugau.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_gaxilt.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000001683_iesdsf.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/US_African_Development_Foundation-0_1_o243vr.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Layer_x0020_1_im1x3n.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Logos_on2sri_hmtqbm.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/image_201_bh9c9h.png',
            'https://res.cloudinary.com/naijakids/image/upload/q_auto/Frame_1000003274_iwnfae.png',]
        cacheImages(imgs)
    }, []);

    return (

        <Layout>
            <Seo
                title="About"
                description="ICE Solar is a climate technology company that uses connected finance and distributed energy resources to serve the underserved and financially excluded."
                link="https://www.icesolar.co/ignite-youth"
            />
            {isLoading ?
                <div id="spinner" className={`container`}>
                    <div className={`loading`}></div>
                </div>
                :
                <div className={classes.About}>
                    <div className={classes.ProdTop}>
                        <h1>
                            What We Do
                        </h1>
                        <h2>
                            ICE Solar is a climate technology
                            company that uses <span className={classes.ProdSpan}> connected finance
                            </span> and <span className={classes.ProdSpan}> distributed energy resources
                            </span> to serve the underserved and
                            financially excluded.
                        </h2>
                    </div>
                    <div className={classes.AboutVision}>
                        <div className={classes.VisionText}>
                            <h1>Our <span> Vision</span>
                            </h1>
                        </div>
                        <div className={classes.VisionMsg}>
                            <div className={classes.VisionMsg1}>
                                <h1>
                                    Transform 1M Businesses
                                </h1>
                                <h2>
                                    We are targeting underserved small businesses who are ready for clean energy and digital revolution
                                </h2>
                            </div>
                            <div className={classes.VisionMsg2}>
                                <h1>
                                    Impact Focused
                                </h1>
                                <h2>
                                    We work with our partners, youth, communities, and businesses to achieve measurable socioeconomic impact</h2>
                            </div>
                            <div className={classes.VisionMsg3}>
                                <h1>
                                    Impact 100 Communities
                                </h1>
                                <h2>
                                    We are targeting underserved, low-income communities that are ready for real change</h2>
                            </div>
                        </div>
                    </div>
                    <div className={classes.SDGS}>
                        <div  className={classes.sdg}>
                            <h1>SDGs <span className={classes.spantext}> Achieved
                            </span>
                            </h1>
                        </div>
                        <div  className={classes.sdgimg}>
                            <div className={classes.imgDiv}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_183_txvpbj.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/v1703377572/444_ucqnpu.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_184_vbiktl.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_185_yr3zph.png' alt='' />

                            </div>
                            <div className={classes.imgDiv}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/v1703377572/999_lrqlt4.png' alt='' />

                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_186_lbfsin.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_187_znxj41.png' alt='' />

                                <img src='https://res.cloudinary.com/naijakids/image/upload/v1703377594/1717_hd7nw8.png' alt='' />

                            </div>
                        </div>
                        <div  className={classes.sdg}>
                            <h1>Our <span className={classes.spantext}> Partners
                            </span>
                            </h1>
                        </div>
                        <div  className={classes.partner}>
                            <h2>
                                Partnerships are vital in ensuring
                                the work we do is sustainable. We
                                aim to attract and collaborate with
                                partners with impact at the core
                                of what they do.
                            </h2>
                        </div>
                        <div  className={`${classes.sponsors} ${classes.mobile}`}>
                            <div className={classes.sponsorIcons}>
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_196_imvftn.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_182_waugau.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_gaxilt.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Group_1000001683_iesdsf.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/US_African_Development_Foundation-0_1_o243vr.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Layer_x0020_1_im1x3n.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Logos_on2sri_hmtqbm.png' alt='' />
                                <img src='https://res.cloudinary.com/naijakids/image/upload/q_auto/image_201_bh9c9h.png' alt='' />
                            </div>
                        </div>
                        <div  className={classes.sponsors}>
                            <img className={classes.webimg} src='https://res.cloudinary.com/naijakids/image/upload/q_auto/Frame_1000003274_iwnfae.png' alt='' />
                        </div>
                    </div>
                </div>
            }
            <Collaborate />
        </Layout>
    )
}

export default About
