import React from 'react'
import classes from './Collaborate.module.css'
import { useNavigate } from 'react-router-dom'
const Collaborate = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/contact-us")
    }

    return (
        <div  className={classes.Collab}>
            <div className={classes.CollabContent}>
                <h1>Ready to Collaborate?</h1>
                <button onClick={handleClick}>
                    Get in Touch
                </button>
            </div>
        </div>
    )
}

export default Collaborate
