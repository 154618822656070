import React from 'react';
import classes from './Footer.module.css'
import { ReactComponent as Facebook } from '../../assets/images/facebook2.svg';
import { ReactComponent as LinkedIn } from '../../assets/images/linkedin2.svg';
import { ReactComponent as Instagram } from '../../assets/images/instagram2.svg';
import { ReactComponent as Twitter } from '../../assets/images/twitter.svg';
 
const Footer = () => {
  return (
    <footer >

      <div className={classes.foot}>
        <div className={classes.footlogo}>
          <img src='https://res.cloudinary.com/naijakids/image/upload/v1703346536/Group_1000002276_1_jxgvpv.png' alt='' />
        </div>
        <div className={classes.smLinks}
        >
          <a href="https://www.facebook.com/icecommpower/about/">
            <Facebook />
          </a>
          <a href='https://ng.linkedin.com/company/icecommpower'>
            <LinkedIn />
          </a>
          <a href="https://www.instagram.com/icecommpower/?hl=en">
            <Instagram />
          </a>
          <a href='https://twitter.com/icecommpower'>
            <Twitter />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
